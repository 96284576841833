import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';
import "firebase/storage";
import  "firebase/messaging";
import { FirebaseConfig } from "./keys";
firebase.initializeApp(FirebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
// export const messaging = firebase.messaging()

export const databaseRef = firebase.database().ref();
export const authRef = firebase.auth();
export const FIREBASE_AUTH_PERSIST = firebase.auth.Auth.Persistence.LOCAL;
export const functions = firebase.functions();
export const bookingRef = databaseRef.child("bookings");
export const lotsRef = databaseRef.child("Lots");
export const singleLotRef = (id) => databaseRef.child("Lots/" + id);
export const singleBidRef = (lid, bid) => databaseRef.child("Lots/" + lid + "/Bids/" + bid);
// export const singleLoadbyRef = (cid, loadId) => databaseRef.child("contracts/" + cid + "/loads/" + loadId);
export const singleLoadRef = (loadId) => databaseRef.child( "loads/" + loadId);
export const truckTypesRef = databaseRef.child("rates/truck_type");
export const driverListRef = (bidderAccountId)=> databaseRef.child("accounts/" + bidderAccountId + "users/");
export const promoRef = databaseRef.child("offers");
export const promoEditRef = (id) => databaseRef.child("offers/"+ id);
export const userRef = databaseRef.child("users");
export const notifyRef = databaseRef.child("notifications/");
export const notifyEditRef = (id) => databaseRef.child("notifications/"+ id);
export const referralRef = databaseRef.child("referral/bonus/amount");
export const singleUserRef = (uid) => databaseRef.child("users/" + uid);
export const allUserRef = databaseRef.child("users/");
export const notifyAdminRef = databaseRef.child('admin_notifications/');
export const notifyAdminEditRef = (id) => databaseRef.child('admin_notifications/' + id);
export const contractRef = databaseRef.child("contracts");
// export const memberRef = databaseRef.child("contracts/" + cid + "/members/" );
export const singleMemberRef = (cid, id) => databaseRef.child("contracts/" + cid + "/members/" + id);
export const allContractRef = databaseRef.child("contracts/");
export const singleContractRef = (cid) =>  databaseRef.child("contracts/" + cid);
export const accountRef = databaseRef.child("accounts");
export const allAccountRef = databaseRef.child("accounts/");
export const singleAccountRef = (id) =>  databaseRef.child("accounts/" + id);
export const storageRef = firebase.storage().ref().child('users/identification_documents/');
export const singleSystemAdminUserList = (id) => databaseRef.child('SystemAdmin_UserNotificationList/' + id);
export const systemAdminNotifyRef = databaseRef.child('SystemAdmin_Notifications');
export const systemAdminEditNotifyRef = (id) => databaseRef.child('SystemAdmin_Notifications/' + id)
export const singleDocumentRef = ( id ) => databaseRef.child("documents/" + id);
export const documentsRef = databaseRef.child("documents");


