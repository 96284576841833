import React,{ useState,useEffect,forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import Autosuggest from 'react-autosuggest';
import AutoCompleteModal from '../components/AutoCompleteModal';
import {
    sendSystemAdminNotification,
    editSystemAdminNotifications
  }  from "../actions/systemadminnotificationactions";



export default function AdminNotifications() {

  const auth = useSelector( state => state.auth.info.profile);

   const onchange = (d) => {
        console.log("Form>", data);
         // setValue(data);
         console.log(data);
    }
    const columns =  [
        {
          title: 'Notification Id',
          field: 'notificationID',
        },
        {
          title: 'Notification Type',
          field: 'notificationType',
        },
        {
          title: "Message",
          field: 'message',
        },
        {
          title: "Read Status",
          field: 'read_status',
          hidden:true,
        }
    ];

  const [data, setData] = useState([]);
  const [value, setValue ] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const notificationadmin = useSelector(state => state.systemNotification);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  useEffect(()=>{
        if(notificationadmin.system_notifications){
            setData(notificationadmin.system_notifications);
        }
       
       
  },[notificationadmin.system_notifications]);

  const onChange = (event, {newValue}) => {
    setValue(newValue);
  }
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(value);
  }
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const popupWindow = () => {
    
  }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type a programming language',
      value,
      onChange: onChange
    };

    if (auth.isAdmin)
    {
  return (
    notificationadmin.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.push_notification_title}
      columns={columns}
      icons={tableIcons}
      data={data}
      editable={{
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editSystemAdminNotifications(newData,"Delete"));
            }, 600);
          }),
      }}
      onRowClick={((evt, selectedRow) => { 
        console.log('selectedRow',selectedRow )  
        setSelectedRow(selectedRow.tableData.id) 
        selectedRow.read_status = true
        dispatch(editSystemAdminNotifications(selectedRow,"Update"));
        } 
      )}
      options={{
        rowStyle: rowData => ({
          backgroundColor: (rowData.read_status === false) ? '#EEE' : '#FFF'
        })
      }}
    />
   
  );
    }
    else
    {
      return(
      <div>
        This page is not accessible.
      </div>
      );
    }
}
