import React,{ useState,useEffect,forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {
     editUser, deleteUser
  }  from "../actions/usersactions";  
import {databaseRef} from '../config/firebase';
import {addAccount, editAccount, deleteAccount} from '../actions/accountsactions';
import UserDetails from '../components/UserDetails';
import {validateEmail, validate} from '../utils/validation';

export default function Users() {
  const [data, setData] = useState([]);
  const [trucks, setTrucks] = useState({});
  const [isDisable, setIsDisable] = useState(true);
  const auth = useSelector( state => state.auth.info.profile);

  const accountsData = useSelector(state => auth.isAdmin ? state.accountsdata :  state.accountsdata?.accounts?.filter( d  => d.accountId === auth.accountId) );
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };



  const dispatch = useDispatch();
  useEffect(()=>{
    if(accountsData.accounts){
        setData(accountsData.accounts);
    }
    else {
      setData(accountsData);
    }
  },[accountsData, accountsData?.accounts]);

  
 
    // validate: rowData => rowData.mobile?.length > 9  && rowData.mobile !==''

  const columns = [
      { title: 'ID', field: 'id', editable:'never',filtering: true},
      { title: languageJson.first_name, field: 'firstName', validate: rowData => rowData.firstName !== '' ,filtering: true },
      { title: languageJson.last_name, field: 'lastName', validate: rowData => rowData.lastName !== '' ,filtering: true},
      // { title: languageJson.user_type, field: 'usertype', lookup: { rider: 'Producer', driver: 'Driver', admin: 'Admin' }, editable:'never',filtering: true},
      { title: 'Company Name', field: 'companyName', validate: rowData => rowData.companyName !== '' ,filtering: true},
      { title: languageJson.email, field: 'email',filtering: true},
      { title: languageJson.mobile, field: 'mobile',filtering: true},
      { title: 'Address', field: 'address', validate: rowData => rowData.address !== '',filtering: true},
      //{ title: 'Created On', field:'CreatedOn', type:'date', editable:'never',filtering: true},
      //{ title: 'Modified On', field:'ModifiedOn', type:'date', editable:'never',filtering: true}
  ];

  return (
    data===null && accountsData.loading? <CircularLoading/>:
    <>
    <MaterialTable
      title={languageJson.TRB || 'Accounts'}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={{
        exportButton: true,
        filtering:true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        detailPanelColumnAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10,20,50]
      }}
      detailPanel={rowData => {
        return (
         <UserDetails {...rowData} />
        )
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      editable= {
        !auth.isAdmin ||
        {
        onRowAdd: newData =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
           
            let tblData = data;
           let error = validate(newData);
          //  console.log(error);
          //   reject();
          //   return;
            if(newData.email !== undefined ){
              if(!validateEmail(newData.email)){
                  reject();
                  return;
                  }
            }else {
                  reject();
                  return;
            }
            resolve();
            console.log(newData.email);
            tblData.push(newData);
            dispatch(addAccount(newData));
          
          }, 600);
        }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve,reject) => {
            setTimeout(() => {
              if(newData.email !== undefined ){
                if(!validateEmail(newData.email))
                    reject();
              }else {
                    reject();
              }
              resolve();
              dispatch(editAccount(newData));
            }, 600);
          }),  
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve();
              dispatch(deleteAccount(oldData));
            }, 600);
          }),   
      }}
    />
    </>
  );
}
