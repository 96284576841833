import React,{ useEffect } from 'react';
import CircularLoading from "./CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { fetchTruckTypes } from "../actions/trucktypeactions";
import { fetchBookings } from "../actions/bookingactions";
import { fetchPromos } from "../actions/promoactions";
import { fetchUsers } from "../actions/usersactions";
import { fetchDrivers } from "../actions/driversactions";
import { fetchBonus } from "../actions/referralactions";
import { fetchNotifications } from "../actions/notificationactions";
import { fetchAdminNotifications } from "../actions/adminnotificationactions";
import { fetchContracts } from "../actions/contractactions";
import { fetchAccounts } from "../actions/accountsactions";
import { fetchLots } from '../actions/lotsactions';
import { fetchDocuments } from '../actions/documentactions';
import { fetchSystemAdminNotifications } from '../actions/systemadminnotificationactions'
function AuthLoading(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    useEffect(()=>{
        if(auth.info){
            dispatch(fetchUsers());
            dispatch(fetchBookings());
            dispatch(fetchTruckTypes());
            dispatch(fetchDrivers());
            dispatch(fetchPromos());
            dispatch(fetchBonus());
            dispatch(fetchNotifications());
            dispatch(fetchAdminNotifications());
            dispatch(fetchContracts());
            dispatch(fetchAccounts());
            dispatch(fetchLots());
            dispatch(fetchDocuments());
            dispatch(fetchSystemAdminNotifications());
        }

    },[auth.info,dispatch]);

    return (
        auth.loading? <CircularLoading/>:props.children
    )
}

export default AuthLoading;