import { 
  FETCH_TRUCK_TYPES,
  FETCH_TRUCK_TYPES_SUCCESS,
  FETCH_TRUCK_TYPES_FAILED,
  EDIT_TRUCK_TYPE
} from "../actions/types";

export const INITIAL_STATE = {
  trucks:null,
  loading: false,
  error:{
    flag:false,
    msg: null
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRUCK_TYPES:
      return {
        ...state,
        loading:true
      };
    case FETCH_TRUCK_TYPES_SUCCESS:
      return {
        ...state,
        trucks:action.payload,
        loading:false
      };
    case FETCH_TRUCK_TYPES_FAILED:
      return {
        ...state,
        trucks:null,
        loading:false,
        error:{
          flag:true,
          msg:action.payload
        }
      };
    case EDIT_TRUCK_TYPE:
      return state;
    default:
      return state;
  }
};