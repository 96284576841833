import React,{ useState,useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import DashboardCard from '../components/DashboardCard';
import Map from '../components/Map';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import  {Currency}  from "../config/CurrencySymbol";
import { FirebaseConfig } from "../config/keys";
const Dashboard = () => {
    const [mylocation, setMylocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const [dailygross,setDailygross] = useState(0);
    const [monthlygross,setMonthlygross] = useState(0);
    const [totalgross,setTotalgross] = useState(0);

    const auth = useSelector( state => state.auth.info.profile);

    const usersdata = useSelector(state => auth.isAdmin ? state.usersdata : state.usersdata?.users?.filter(d => d.accountId === auth.accountId) );
    const bookingdata = useSelector(state => state.bookingdata);
    


    useEffect(()=>{
        if(mylocation == null){
            navigator.geolocation.getCurrentPosition(
                position => setMylocation({ 
                    lat: position.coords.latitude, 
                    lng: position.coords.longitude
                }), 
                err => console.log(err)
            );
        }
    },[mylocation]);

    useEffect(()=>{
        if(usersdata?.users){
            const drivers = usersdata.users.filter(({ usertype }) => usertype === 'driver' );  
            let locs = [];
            if (drivers)
            {
                for(let i=0;i<drivers.length;i++){
                    if(drivers[i].approved && drivers[i].driverActiveStatus && drivers[i].location){
                        locs.push({
                            id:i,
                            lat:drivers[i].location.lat,
                            lng:drivers[i].location.lng,
                            drivername:drivers[i].firstName + ' ' + drivers[i].lastName
                        });
                    }
                }
                setLocations(locs);
            }
        }
    },[usersdata?.users]);

    useEffect(()=>{
        if(bookingdata.bookings){
            let today =  new Date();
            var tdTrans = 0.0;
            var mnTrans = 0.0;
            var totTrans = 0.0;
            for(let i=0;i<bookingdata.bookings.length;i++){
                const {trip_cost, tripdate} = bookingdata.bookings[i];
                let tDate = new Date(tripdate);
                console.log("Booking ID", bookingdata.bookingId);
                console.log("Trip Cost", trip_cost);
                if(trip_cost>=0){
                    if(tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()){
                        tdTrans  = tdTrans + parseFloat(trip_cost);
                    }          
                    if(tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()){
                        mnTrans  = mnTrans + parseFloat(trip_cost);
                    }
                    
                    totTrans  = totTrans + parseFloat(trip_cost);
                }
           
            }
            setDailygross(tdTrans.toFixed(2));
            setMonthlygross(mnTrans.toFixed(2));
            setTotalgross(totTrans.toFixed(2));
        }
    },[bookingdata.bookings]);

    const url = `https://maps.googleapis.com/maps/api/js?key=${FirebaseConfig.googleMapsApiKey}&v=3.exp&libraries=geometry,drawing,places`;
    return (
        bookingdata.bookings?.length === 0 && bookingdata.loading ? <CircularLoading/> :
        <div>
             {auth.isAdmin &&
            <Typography variant="h4" style={{margin:"20px 0 0 15px"}}>{languageJson.gross_earning}</Typography>}
            {auth.isAdmin ?
            <Grid container direction="row" spacing={2}>
                <Grid item xs>
                    <DashboardCard title={languageJson.today_text} image={require("../assets/money1.jpg")}>{ Currency + dailygross}</DashboardCard>
                </Grid>
                <Grid item xs>
                    <DashboardCard title={languageJson.this_month_text} image={require("../assets/money2.jpg")}>{ Currency + monthlygross}</DashboardCard>
                </Grid>
                <Grid item xs>
                    <DashboardCard title={languageJson.total} image={require("../assets/money3.jpg")}>{ Currency + totalgross}</DashboardCard>
                </Grid>
            </Grid>
            : null }
            { mylocation?
            <Paper style={{marginTop:'25px'}}>
                <Typography variant="h4" style={{margin:"20px 0 0 15px"}}>{languageJson.real_time_driver_section_text}</Typography>
                <Map mapcenter={mylocation} locations={locations}
                    googleMapURL={url}
                    loadingElement={<div style={{ height: `480px` }} />}
                    containerElement={<div style={{ height: `480px` }} />}
                    mapElement={<div style={{ height: `480px` }} />}
                />
            </Paper>
            :
            <Typography variant="h4" style={{margin:"20px 0 0 15px",color:'#FF0000'}}>{languageJson.allow_location}</Typography>
            }
        </div>
        
    )
}

export default Dashboard;