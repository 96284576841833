import React , { useEffect } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { data } from '../model/dummydata';
let suggestions =  new Array();

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;
  
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

 const IntegrationAutosuggest = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    single: '',
    popper: '',
  });

  const [users, setUsers] = React.useState(null); 
  const usersData = useSelector(state => state.usersdata);

  const [contracts, setContracts] = React.useState(null); 
  const contractsData = useSelector(state => state.contractsdata);

  const [accounts, setAccounts] = React.useState(null); 
  const accountsData = useSelector(state => state.accountsdata);
  useEffect(()=>{
    console.log(props);
   if(props.data.type === 'user') {
    suggestions = [];
    if(usersData.users){
      setUsers(usersData.users)
      console.log(usersData.users);
      let newItem;
      for (let user of usersData.users){
        newItem = {id: user['id'], label: user['email']};
        // suggestions.add(newItem)
        suggestions.indexOf(newItem) === -1 ? suggestions.push(newItem) : console.log("This item already exists");
       }
     }
    }else if(props.data.type === 'contract'){
      suggestions = [];
      if(contractsData.contracts){
        setUsers(usersData.contracts)
        let newItem;
        for (let contract of contractsData.contracts){
          newItem = {id:contract['id'] ,label: contract['id']};
          // suggestions.add(newItem)
          suggestions.indexOf(newItem) === -1 ? suggestions.push(newItem) : console.log("This item already exists");
         }
       }

    }else {
      suggestions = [];
      if(accountsData.accounts){
         setAccounts(accountsData.accounts); let newItem;
         for (let account of accountsData.accounts){
           newItem = {id: account['id'] , label: account['id']};
           // suggestions.add(newItem)
           suggestions.indexOf(newItem) === -1 ? suggestions.push(newItem) : console.log("This item already exists");
          }
      }
    }
   
},[]);

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue,
    });
    let data = suggestions.filter( suggestion => suggestion.label === newValue);
    // props.id  = 'sajid';
    (props.data.type === 'user') ? props.onchange(data) : props.onchange(newValue) ;

  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          label: 'Search',
          placeholder: 'Search...',
          value: state.single,
          onChange: handleChange('single'),
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

export default IntegrationAutosuggest;