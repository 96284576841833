import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { uploadFile } from '../actions/usersactions';
import { storageRef } from "../config/firebase";
import { uuid } from 'uuidv4';


const FileUploadDocuments = ({onClick, onClose, onChange}) => {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [percentUploaded, setPercentUpload] = useState(0);
    const dispatch = useDispatch();
 
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

	const handleSubmission = () => {
        const formData = new FormData();

		formData.append('File', selectedFile);
         // console.log(formData.get(), selectedFile);
       
         const filePath = `driver_licenses/${uuid()}/`;

         storageRef.child(filePath).put(selectedFile).then((snapshot) => {
          const percentUploaded = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setPercentUpload(percentUploaded);
          if(percentUploaded == 100){
            snapshot.ref.getDownloadURL().then(downloadUrl => {
              console.log('this is the image url', downloadUrl);
              //onClose();
              //onChange(downloadUrl);
            });
          }
        })
         // dispatch(uploadFile(selectedFile));


	}

	return(
        <div style={{margin:'25px'}}>
          <h3>Documentation</h3>  
        <input type="file" name="file" onChange={changeHandler} />
        {isSelected ? (
            <div>
                <p>Filename: {selectedFile.name}</p>
                <p>Filetype: {selectedFile.type}</p>
                <p>Size in bytes: {selectedFile.size}</p>
                <p>
                    lastModifiedDate:{' '}
                    {selectedFile.lastModifiedDate.toLocaleDateString()}
                </p>
                <p>
                uploading: {percentUploaded}
                </p>
            </div>
        ) : (
            <p>Select a file to show details</p>
        )}
        <div>
            <button onClick={handleSubmission}>Submit</button>
        </div>
    </div>
	)
}

export default FileUploadDocuments;