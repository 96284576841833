import React,{ useState,useEffect,forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import  languageJson  from '../config/language';
import { editContract } from '../actions/contractactions';
//import  MembersDetails  from '../components/MembersDetails';
import LoadsDetails from '../components/LoadsDetails';
export default function Contracts() {
 const [trucks, setTrucks] = useState ([]);
    const columns =  [
        { title: 'Contract Id', field: 'displayId',  editable: 'never' },
        { title: languageJson.grain_type, field: 'grainType' },
        { title: languageJson.contract_start_date, field: 'startDate', type:'date' },
        { title: languageJson.contract_due_date, field: 'endDate', type:'date' },
        { title: languageJson.contract_start_location, field: 'startLocation'  },
        { title: languageJson.contract_lat_start,field: 'startLatitude', type:'numeric'},
        { title: languageJson.contract_lon_start, field: 'startLongitude', type: 'numeric' },
        { title: languageJson.contract_end_location, field: 'endLocation' },
        { title: languageJson.contract_lat_end, field: 'endLatitude' },
        { title: languageJson.contract_lon_end, field: 'endLongitude' },
        { title: 'Full Contract Bid', field: 'fullContractBid', lookup: { True: 'True', False:'False' } },
        { title: 'Total Loads', field: 'loadsTotal', type: 'numeric' },
        { title: 'Load Completed', field: 'loadsCompleted', type: 'numeric' },
        { title: languageJson.contract_percent, field: 'percentComplete' },
        { title: languageJson.contract_price + ' per load', field: 'price', type: 'currency' },
        { title: 'Paid In Full', field: 'paidInFull', type: 'currency' },
        { title: 'Tons', field: 'tons', type: 'numeric' },
        { title: 'Shipper Account', field: 'accountId', editable:'never'},
        { title: 'Driver Account', field: 'bidderAccountId', editable: 'never'},
        { title: languageJson.contract_status, field: 'status',  lookup: { Created: "Created", Withdrawn: "Withdrawn", Active: "Active", OnHold: "On-Hold", Completed: "Completed", Closed: "Closed" } },
       { title: languageJson.bid_truck_type, field: 'truckType', lookup:  trucks.reduce((a, v) => ({ ...a, [v]: v}), {})  },
        { title: 'Rate Class ', field: 'rateClass' , lookup: {"Primary Rate": "Primary Rate", "Secondary Rate": "Secondary Rate" }},
        { title: 'Created On', field:'CreatedOn', type:'date', editable:'never', defaultSort: 'desc'},
        { title: 'Modified On', field:'ModifiedOn', type:'date', editable:'never'}

        ];

  const [data, setData] = useState([]);

  const auth = useSelector( state => state.auth.info.profile);
  //const truckType = useSelector(state => state.trucktypes.trucks);
  const contractsData = useSelector(state => auth.isAdmin ?  state.contractsdata.contracts : state.contractsdata.contracts?.filter(d => (d.accountId === auth.accountId || d.bidderAccountId === auth.accountId)));
  const dispatch = useDispatch();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  useEffect(()=>{
   // console.log("Contract Data", contractsData);
    //console.log("Contract Data Contracts", contractsData.contracts);
    if((data?.length === 0 && contractsData?.length > 0 ) || data?.length < contractsData?.length) {

        setData(contractsData);
    }
  },[contractsData,data]);
  console.log("data", data);
  console.log("contracts Data", contractsData);

  return (
    contractsData?.loading? <CircularLoading/>: (data !== null?
    <MaterialTable
      title={languageJson.contracts}
      columns={columns}
      data={contractsData}
      icons={tableIcons}
      options={{
        filtering: true,
        exportButton: true,
        exportAllData: true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10,20,50]
      }}
      detailPanel={rowData => {
        return(
        //<MembersDetails row={rowData} />
        <LoadsDetails row={rowData} />
        )
      }}
      editable={auth.isAdmin &&{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.push(newData);
              // dispatch(editBid(newData));
            //   dispatch(editNotifications(newData,"Add"));
            }, 600);
          }),

          onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
              dispatch(editContract(newData, "Update"));
            }, 600);
          }),
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editContract(newData,"Delete"));
            }, 600);
          }),
      }}
    />:<div></div>)
  );
}
