import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from 'redux-logger'

import auth from "./authreducer";
import trucktypes from "./trucktypesreducer";
import bookingdata from "./bookingsreducer";
import promodata from "./promoreducer";
import usersdata from "./usersreducer";
import referraldata from "./referralreducer";
import notificationdata from "./notificationreducer";
import notificationadmin from "./adminnotificationreducer";
import contractsdata from "./contractsreducer";
import accountsdata from "./accountsreducer";
import lotsdata from './lotsreducer';
import documentsdata from './documentsreducer';
import systemNotification from './systemAdminnotificationreducer'
const reducers = combineReducers({
  auth,
  trucktypes,
  bookingdata,
  promodata,
  usersdata,
  referraldata,
  notificationdata,
  notificationadmin,
  contractsdata,
  accountsdata,
  lotsdata,
  documentsdata,
  systemNotification,
});

let middleware = [];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}

export const store = createStore(reducers, {}, applyMiddleware(...middleware));