import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

 const SelectElement = ({onClick, onFileChange}) => {
    const classes = useStyles();
    const [file, setFile] = React.useState('');
  
    const handleChange = (event) => {
      setFile(event.target.value);
      onFileChange(event.target.value);
    };

    return(
        <>
            <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">File Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={file}
                onClick={onClick}
                onChange={handleChange}
                >
                <MenuItem value={'Identification'}>Identification</MenuItem>
                <MenuItem value={'documentation'}>Documentation</MenuItem>
            </Select>
            </FormControl>
        </>
    )

}

export default SelectElement;