import { contractsRef, singleLoadRef } from "../config/firebase";
import moment from 'moment';
import { 
   
    EDIT_LOAD,
    EDIT_LOAD_SUCCESS,
    EDIT_LOAD_FAILED,
    DELETE_LOAD,
    DELETE_LOAD_SUCCESS,
    DELETE_LOAD_FAILED
} from "./types";


    export const editLoad = (load, method) => dispatch =>{
      dispatch({
        type: EDIT_LOAD,
        payload: load
      });
      if (load.suggestedStart)
      {
        //alert(moment(load.suggestedStart).format('YYYY/MM/DD HH:mm:ss'));
       // alert(load.driver);
        load.suggestedStart = moment(load.suggestedStart).local().format('YYYY/MM/DD HH:mm:ss');
      }
      load.ModifiedOn = moment(load.loadDateTime).format('YYYY/MM/DD HH:mm:ss');
      
      if(method === 'Add' ){
        // lotsRef.push(lot);
      }else if(method === 'Delete'){
        singleLoadRef(load.loadId).remove();  // lid : Lot ID , load : Bid ID
      }else{
        singleLoadRef(load.loadId).set(load).then(()=>{
                dispatch({
                  type: EDIT_LOAD_SUCCESS,
                  payload: null
                });  
              }).catch((error)=>{
                dispatch({
                  type: EDIT_LOAD_FAILED,
                  payload: error
                });   
            });     
        }
    }
  
    export const deleteLoad = (id) => dispatch =>{
      dispatch({
        type: DELETE_LOAD,
        payload: id
      });
  
    //   singleLotRef(id).remove().then(()=>{
    //     dispatch({
    //       type: DELETE_LOAD_SUCCESS,
    //       payload: null
    //     });  
    //   }).catch((error)=>{
    //     dispatch({
    //       type: DELETE_LOAD_FAILED,
    //       payload: error
    //     });        
    //   });
    }
