import React,{ useState,useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../assets/logo.png';
import { useSelector  } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import  languageJson  from "../config/language";
import firebase from 'firebase'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey,
    width:192,
    height:192
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const auth = useSelector(state => state.auth);
  const classes  = useStyles();
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false)
  useEffect(()=>{
    if(auth.info){
      props.history.push('/');
    }
  });

  const handleEmailChange = (e) =>{
    setEmail(e.target.value);
  }  
  
  const onPressForgotPass =(e) =>{
    e.preventDefault();
    var auth = firebase.auth();
    return auth.sendPasswordResetEmail(email)
    .then((res) =>{
    //console.log("A Password Reset Link sent to your email please check and reset your New Password")
      setTimeout(() => {
          alert(languageJson.PasswordResetEmail);        
      }, 600);
  
    })
    .catch((error) =>{
    alert(error)  
    }) 
}

const handleClose = () => {
    setEmail("");
    //dispatch(clearLoginError());
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={logo} alt="Logo" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {languageJson.ResetPassword}
        </Typography>
        <form className={classes.form} onSubmit={onPressForgotPass}>
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={languageJson.email_address}
              name="email"
              autoComplete="email"
              onChange={handleEmailChange}
              value={email}
              autoFocus
          />
          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
          >
              {languageJson.Submit}
          </Button>
        </form>
      </div>
      <AlertDialog open={auth.error.flag} onClose={handleClose}>{languageJson.PasswordResetEmail}</AlertDialog>
    </Container>
  );
  
}

export default Login;
