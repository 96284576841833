import { lotsRef, singleLotRef } from "../config/firebase";
import moment from 'moment';
import { 
    FETCH_LOTS,
    FETCH_LOTS_SUCCESS,
    FETCH_LOTS_FAILED,
    EDIT_LOTS,
    EDIT_LOT_SUCCESS,
    EDIT_LOT_FAILED,
    DELETE_LOT,
    DELETE_LOT_SUCCESS,
    DELETE_LOT_FAILED
} from "./types";

export const fetchLots = () => dispatch => {
    dispatch({
      type: FETCH_LOTS,
      payload: null
    });
    lotsRef.on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i
        //   data[i].pickupAddress =  data[i].pickup.add;
        //   data[i].dropAddress =  data[i].drop.add;
        //   data[i].discount =  data[i].discount_amount?data[i].discount_amount:0;
          return data[i]
        });
        dispatch({
          type: FETCH_LOTS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_LOTS_FAILED,
          payload: "No lots available."
        });
      }
    });
  };
    export const editLot = (lot, method) => dispatch =>{
      dispatch({
        type: EDIT_LOTS,
        payload: lot
      });
      let editedLot = lot;
      lot.endDate = moment(lot.endDate).format('MM/D/YYYY');
      lot.startDate = moment(lot.startDate).format('MM/D/YYYY');
      if(method === 'Add' ){
        lotsRef.push(lot);
      }else if(method === 'Delete'){
        singleLotRef(lot.id).remove();
      }else{
        singleLotRef(lot.id).set(lot);
      }
    }
  
    export const deleteLot = (id) => dispatch =>{
      dispatch({
        type: DELETE_LOT,
        payload: id
      });
  
      singleLotRef(id).remove().then(()=>{
        dispatch({
          type: DELETE_LOT_SUCCESS,
          payload: null
        });  
      }).catch((error)=>{
        dispatch({
          type: DELETE_LOT_FAILED,
          payload: error
        });        
      });
    }
