export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAILED = "FETCH_DRIVERS_FAILED";

export const FETCH_TRUCK_TYPES = "FETCH_TRUCK_TYPES";
export const FETCH_TRUCK_TYPES_SUCCESS = "FETCH_TRUCK_TYPES_SUCCESS";
export const FETCH_TRUCK_TYPES_FAILED = "FETCH_TRUCK_TYPES_FAILED";
export const EDIT_TRUCK_TYPE = "EDIT_TRUCK_TYPE";

export const FETCH_BOOKINGS= "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILED = "FETCH_BOOKINGS_FAILED";

export const FETCH_LOTS= "FETCH_LOTS";
export const FETCH_LOTS_SUCCESS = "FETCH_LOTS_SUCCESS";
export const FETCH_LOTS_FAILED = "FETCH_LOTS_FAILED";
export const EDIT_LOTS = "EDIT_LOTS";
export const EDIT_LOT_FAILED = "EDIT_LOT_FAILED";
export const EDIT_LOT_SUCCESS = "EDIT_LOT_SUCCESS";
export const SEND_LOT = "SEND_LOT";
export const SEND_LOT_SUCCESS = "SEND_LOT_SUCCESS";
export const SEND_LOT_FAILED = "SEND_LOT_FAILED";
export const DELETE_LOT = "DELETE_LOT";
export const DELETE_LOT_SUCCESS = "DELETE_LOT_SUCCESS";
export const DELETE_LOT_FAILED = "DELETE_LOT_FAILED";

export const EDIT_BID = "EDIT_BID";
export const EDIT_BID_SUCCESS = "EDIT_BID_SUCCESS";
export const EDIT_BID_FAILED = "EDIT_BID_FAILED";
export const DELETE_BID = "DELETE_BID";
export const DELETE_BID_SUCCESS = "DELETE_BID_SUCCESS";
export const DELETE_BID_FAILED = "DELETE_BID_FAILED";

export const EDIT_LOAD = "EDIT_LOAD";
export const EDIT_LOAD_SUCCESS = "EDIT_LOAD_SUCCESS";
export const EDIT_LOAD_FAILED = "EDIT_LOAD_FAILED";
export const DELETE_LOAD = "DELETE_LOAD";
export const DELETE_LOAD_SUCCESS = "DELETE_LOAD_SUCCESS";
export const DELETE_LOAD_FAILED = "DELETE_LOAD_FAILED";

export const FETCH_PROMOS= "FETCH_PROMOS";
export const FETCH_PROMOS_SUCCESS = "FETCH_PROMOS_SUCCESS";
export const FETCH_PROMOS_FAILED = "FETCH_PROMOS_FAILED";
export const EDIT_PROMOS = "EDIT_PROMOS";

export const FETCH_ALL_USERS= "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";
export const ADD_USERS= "ADD_USERS";
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
export const ADD_USERS_FAILED = "ADD_USERS_FAILED";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const FETCH_REFERRAL_BONUS= "FETCH_REFERRAL_BONUS";
export const FETCH_REFERRAL_BONUS_SUCCESS = "FETCH_REFERRAL_BONUS_SUCCESS";
export const FETCH_REFERRAL_BONUS_FAILED = "FETCH_REFERRAL_BONUS_FAILED";
export const EDIT_REFERRAL_BONUS = "EDIT_REFERRAL_BONUS";
export const CLEAR_REFERRAL_ERROR = "CLEAR_REFERRAL_ERROR";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const ADMIN_FETCH_NOTIFICATIONS = "ADMIN_FETCH_NOTIFICATIONS";
export const ADMIN_FETCH_NOTIFICATIONS_SUCCESS = "ADMIN_FETCH_NOTIFICATIONS_SUCCESS";
export const ADMIN_FETCH_NOTIFICATIONS_FAILED = "ADMIN_FETCH_NOTIFICATIONS_FAILED";
export const ADMIN_EDIT_NOTIFICATIONS = "ADMIN_EDIT_NOTIFICATIONS";
export const ADMIN_SEND_NOTIFICATION = "ADMIN_SEND_NOTIFICATION";
export const ADMIN_SEND_NOTIFICATION_SUCCESS = "ADMIN_SEND_NOTIFICATION_SUCCESS";
export const ADMIN_SEND_NOTIFICATION_FAILED = "ADMIN_SEND_NOTIFICATION_FAILED";

export const SYSTEM_ADMIN_FETCH_NOTIFICATIONS = "SYSTEM_ADMIN_FETCH_NOTIFICATIONS";
export const SYSTEM_ADMIN_FETCH_NOTIFICATIONS_SUCCESS = "SYSTEM_ADMIN_FETCH_NOTIFICATIONS_SUCCESS";
export const SYSTEM_ADMIN_FETCH_NOTIFICATIONS_FAILED = "SYSTEM_ADMIN_FETCH_NOTIFICATIONS_FAILED";
export const SYSTEM_ADMIN_EDIT_NOTIFICATIONS = "SYSTEM_ADMIN_EDIT_NOTIFICATIONS";
export const SYSTEM_ADMIN_SEND_NOTIFICATION = "SYSTEM_ADMIN_SEND_NOTIFICATION";
export const SYSTEM_ADMIN_SEND_NOTIFICATION_SUCCESS = "SYSTEM_ADMIN_SEND_NOTIFICATION_SUCCESS";
export const SYSTEM_ADMIN_SEND_NOTIFICATION_FAILED = "SYSTEM_ADMIN_SEND_NOTIFICATION_FAILED";



export const FETCH_ALL_CONTRACTS= "FETCH_ALL_CONTRACTS";
export const FETCH_ALL_CONTRACTS_SUCCESS = "FETCH_ALL_CONTRACTS_SUCCESS";
export const FETCH_ALL_CONTRACTS_FAILED = "FETCH_ALL_CONTRACTS_FAILED";
export const EDIT_CONTRACT = "EDIT_CONTRACT";
export const EDIT_CONTRACT_SUCCESS = "EDIT_CONTRACT_SUCCESS";
export const EDIT_CONTRACT_FAILED = "EDIT_CONTRACT_FAILED";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";
export const DELETE_CONTRACT_FAILED = "DELETE_CONTRACT_FAILED";

export const EDIT_MEMBER = "EDIT_MEMBER";

export const FETCH_ALL_ACCOUNTS= "FETCH_ALL_ACCOUNTS";
export const FETCH_ALL_ACCOUNTS_SUCCESS = "FETCH_ALL_ACCOUNTS_SUCCESS";
export const FETCH_ALL_ACCOUNTS_FAILED = "FETCH_ALL_ACCOUNTS_FAILED";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILED = "ADD_ACCOUNT_FAILED";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAILED = "EDIT_ACCOUNT_FAILED";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED";

export const FILE_UPLOADED = "FILE_UPLOADED";
export const FILE_UPLOADED_SUCCESS = "FILE_UPLOADED_SUCCESS";
export const FILE_UPLOADED_FAILED = "FILE_UPLOADED_FAILED";

export const FETCH_ALL_DOCUMENTS = "FETCH_ALL_DOCUMENTS";
export const FETCH_ALL_DOCUMENTS_SUCCESS = "FETCH_ALL_DOCUMENTS_SUCCESS";
export const FETCH_ALL_DOCUMENTS_FAILED = "FETCH_ALL_DOCUMENTS_FAILED";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const EDIT_DOCUMENT_FAILED = "EDIT_DOCUMENT_FAILED";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILED = "DELETE_DOCUMENT_FAILED";