import { lotsRef, singleBidRef } from "../config/firebase";
import moment from 'moment';
import { 
   
    EDIT_BID,
    EDIT_BID_SUCCESS,
    EDIT_BID_FAILED,
    DELETE_BID,
    DELETE_BID_SUCCESS,
    DELETE_BID_FAILED
} from "./types";


    export const editBid = (bid, method) => dispatch =>{
      dispatch({
        type: EDIT_BID,
        payload: bid
      });
      bid.endDate = moment(bid.endDate).format('YYYY/MM/DD');
      bid.startDate = moment(bid.startDate).format('YYYY/MM/DD');
      bid.ModifiedOn = moment(bid.bidDateTime).format('YYYY/MM/DD HH:mm:ss');
      if(method === 'Add' ){
        // lotsRef.push(lot);
      }else if(method === 'Delete'){
        singleBidRef(bid.lid, bid.bid).remove();  // lid : Lot ID , bid : Bid ID
      }else{
        singleBidRef(bid.lid, bid.bid).set(bid).then(()=>{
                dispatch({
                  type: EDIT_BID_SUCCESS,
                  payload: null
                });  
              }).catch((error)=>{
                dispatch({
                  type: EDIT_BID_FAILED,
                  payload: error
                });   
            });     
        }
    }
  
    export const deleteBid = (id) => dispatch =>{
      dispatch({
        type: DELETE_BID,
        payload: id
      });
  
    //   singleLotRef(id).remove().then(()=>{
    //     dispatch({
    //       type: DELETE_BID_SUCCESS,
    //       payload: null
    //     });  
    //   }).catch((error)=>{
    //     dispatch({
    //       type: DELETE_BID_FAILED,
    //       payload: error
    //     });        
    //   });
    }
