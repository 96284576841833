export const FirebaseConfig = {
  apiKey: "AIzaSyB7fbsfRwkmcyaw9gviSmMirypukhu94Us",
  googleMapsApiKey: "AIzaSyAQcM3YnPFs0pm_RMIm94IdDcHyBd2EDzc",
  authDomain: "gograin-dev-001.firebaseapp.com",
  databaseURL: "https://gograin-dev-001-default-rtdb.firebaseio.com",
  projectId: "gograin-dev-001",
  storageBucket: "gograin-dev-001.appspot.com",
  messagingSenderId: "106784109452",
  appId: "1:106784109452:web:2da18c6df676c208751ca9",
  measurementId: "G-85V8MLK8S7",
  braintreeToken: "sandbox_ktrfwfgp_5p2rzydxhxpxkmxx"
  };