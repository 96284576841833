import { 
    FETCH_ALL_ACCOUNTS,
    FETCH_ALL_ACCOUNTS_SUCCESS,
    FETCH_ALL_ACCOUNTS_FAILED,
    ADD_ACCOUNT,
    ADD_ACCOUNT_SUCCESS,
    ADD_ACCOUNT_FAILED
  } from "../actions/types";
  
  export const INITIAL_STATE = {
    accounts:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_ACCOUNTS:
        return {
          ...state,
          loading:true
        };
      case FETCH_ALL_ACCOUNTS_SUCCESS:
        return {
          ...state,
          accounts:action.payload,
          loading:false
        };
      case FETCH_ALL_ACCOUNTS_FAILED:
        return {
          ...state,
          accounts:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
        case ADD_ACCOUNT:
        return {
          ...state,
          loading:true,
          
        };
        case ADD_ACCOUNT_SUCCESS:
        return {
          ...state,
          loading:false,
        };
        case ADD_ACCOUNT_FAILED:
        return {
          ...state,
          accounts:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      default:
        return state;
    }
  };