import { truckTypesRef } from "../config/firebase";
import { 
    FETCH_TRUCK_TYPES,
    FETCH_TRUCK_TYPES_SUCCESS,
    FETCH_TRUCK_TYPES_FAILED,
    EDIT_TRUCK_TYPE
} from "./types";

export const fetchTruckTypes = () => dispatch => {
    dispatch({
      type: FETCH_TRUCK_TYPES,
      payload: null
    });
    truckTypesRef.on("value", snapshot => {
      if (snapshot.val()) {
        dispatch({
          type: FETCH_TRUCK_TYPES_SUCCESS,
          payload: snapshot.val()
        });
      } else {
        dispatch({
          type: FETCH_TRUCK_TYPES_FAILED,
          payload: "No trucks available."
        });
      }
    });
  };

  export const editTruckType = (truckTypes,method) => dispatch =>{
    dispatch({
      type: EDIT_TRUCK_TYPE,
      payload: method
    });
    truckTypesRef.set(truckTypes);
  }