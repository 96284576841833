import React,{ useState,useEffect,forwardRef } from 'react';
import MaterialTable, { MTableAction, MTableToolbar} from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
//import Save from '@material-ui/icons/Save'
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import CircularLoading from "./CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import  languageJson  from "../config/language";
import { editDocument, deleteDocument } from '../actions/documentactions';
import SelectElement  from './SelectElement';
import  { mimeIconMarkup }  from "../utils/Utils";

import FileUploadModal from './FileUploadModal';
export default function RecordDetails({row,syncType, recordId}) {
  console.log("Row",row.bookingId);
  console.log("Sync Type", syncType);
  console.log("Rec Id", recordId);
  console.log("State Document Data",useSelector(state => state.documentsdata.documents));
    const usersdata = useSelector(state => state.usersdata);
    const auth = useSelector( state => state.auth.info);

    const documentsBookingData = useSelector(state=> state.documentsdata.documents.filter(d =>  d.syncType === syncType && d.recordId === row.bookingId));
    const documentsUserData = useSelector(state=> state.documentsdata.documents.filter(d =>  d.syncType === syncType && d.recordId === recordId));
    const [downloadUrl, setDownloadUrl] =  useState("");
    // let trucks = truckType.map(d =>   d.name); 
    const columns =  [
        { title: languageJson.TRB || 'File', field: 'filePath' , editable:'never', render: rowData=> rowData.filePath? <div><a href={rowData.filePath} target='_blank' rel="noreferrer">{mimeIconMarkup(rowData.mime)} {rowData.fileName}</a></div>:null},  //render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never'
        { title: languageJson.TRB || 'Mime Type',  field: 'mime', editable:'never'},
        { title: languageJson.TRB || 'Sync Type',  field: 'syncType'},
        { title: languageJson.TRB || 'File Type',  field: 'fileType',
        render: rowData => {
           return ( rowData.fileType )
        },
        editComponent: rowData => 
          <SelectElement onClick={onClickHandle} onFileChange={value => rowData.onChange(value) } />
      },
        { title: languageJson.TRB || 'Start Date', field: 'startDate', type:'date' },
        { title: languageJson.TRB || 'Expiry Date',field: 'expiryDate', type:'date'},
        { title: languageJson.TRB || 'ID Number', field: 'numberId'},
        { title: languageJson.TRB || 'Uploader', field: 'userId',editable:'never'},
        {title: languageJson.TRB || 'File ID', field: 'documentId', editable: 'never'},
        ];

  const [isOpen, setIsOpen] = useState(false);      
  const [data, setData] = useState([]);
  let documentsData = [];

  if (syncType === "Booking")
  documentsData = documentsBookingData;

  if (syncType === "User")
  documentsData = documentsUserData;

  const dispatch = useDispatch();
  const unmounted = React.useRef(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


  useEffect(()=>{
        // let bidder = [];
        // if(rowData.row.Bids){
        //     for(let key in rowData.row.Bids){
        //         rowData.row.Bids[key].bid = key;
        //         rowData.row.Bids[key].lid = rowData.row.id;
        //         bidder.push(rowData.row.Bids[key]);
        //     }
        //     if(!unmounted.current)
        console.log(documentsData)
        if(documentsData.documents){
             setData(documentsData.documents);
        }else {
          setData(documentsData);
        }
       

        // }
       
  },[documentsData?.documents]);
  const handleClick = rows => {
    // selectedRow.current = rows;
    console.log('selected',rows);
    };

  const onClickHandle = (data) => {
    setIsOpen(!isOpen);
    // console.log('sdds',data.target.value);
  } 
 
 const onChangeHandler = (url) => {
   setDownloadUrl(url);
   setIsOpen(false);

 }

  return (
    documentsBookingData.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.TRB || 'Documents'}
      columns={columns}
      icons={tableIcons}
      data={data}
      options={{
        exportButton: true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        detailPanelColumnAlignment: "left",
      }}
      components={{
        Toolbar: props => (
            <div style={{ backgroundColor: '#e8eaf5' }}>
                <MTableToolbar {...props} />
                <FileUploadModal isOpen={isOpen} onChange={onChangeHandler}/>
            </div>
        ),
    }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.push(newData);
              newData.filePath = downloadUrl;
              newData.userId = row.id;
              newData.accountId = row.accountId;
              dispatch(editDocument(newData))
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
            }, 600);
          }),
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteDocument(newData.id));
            }, 600);
          }),
      }}
    />
  );
}
