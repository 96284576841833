import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import AutoComplete from './AutoComplete';


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const AutoCompleteModal = (props)  => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [value,setValue ] = useState(props.value);
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      if(props.value.length < 1 || props.value === undefined){
        console.log('*** No Selection***');
      }else{
            setValue(props.value[0].label);
      }
    };
     
    const onchange = (data) => {
        setValue(data);
        props.onchange(data);
       
    }

    return (
      <div>
         <input
          type="text"
          value={value}
          onClick={handleOpen} 
        />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
         
            <div style={modalStyle} className={classes.paper}>
              <AutoComplete  data={props} onchange = { e => { onchange(e);}}  />
            
           
            </div>
          
        </Modal>
      </div>
    );

};

export default React.forwardRef(AutoCompleteModal);